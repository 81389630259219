<template>
  <section class="site-projects">

    <div class="content first-container content-splash">
      <div class="hero-banner">
        <div class="vid">
          <video playsinline autoplay muted loop>
            <source src="@/assets/japan.mp4" type="video/mp4">
          </video>
        </div>

        <div class="text-container">
          <div class="hero_title text-uppercase">
            My Projects
          </div>
          <div class="hero_subtitle">
            View all my official projects ranging from mobile apps, websites to EDMs
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid">
      <div class="filters">
        <div class="row">
          <div class="col-lg-auto col-4">
            <a class="filter" data-filter='vue'>Vue JS</a>
          </div>

          <div class="col-lg-auto col-4">
            <a class="filter" data-filter='rn'>React Native</a>
          </div>

          <div class="col-lg-auto col-4">
            <a class="filter" data-filter='others'>Others</a>
          </div>
        </div>
      </div>
    </div> -->

    <div class="content">
      <div class="container-fluid test" data-list-searchjs>
        <div v-masonry transition-duration="0.3s" item-selector=".item-card" column-width=".item-card" gutter="15" class="masonry-container">
          <router-link v-for="item in projectsData" :key="item.id" v-masonry-tile class="item-card" :to="item.path">
            <img class="w-100 image-fluid" :src="item.thumbnail" :alt="item.title">
            <span class="project-name">{{item.title}}</span>
          </router-link>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { ref, onMounted, onBeforeMount, onBeforeUnmount, reactive, computed } from 'vue';
import Func from '@/helpers/Function';
import Footer from '@/components/Footer.vue';
import { useStore } from 'vuex';
import Data from '@/helpers/Data';

export default {
  components: { 
    Footer: Footer
  },
  setup(props, {emit}) {
    const store = useStore();
    const userMode = computed(() => store.state.account.mode);
    const projectsData = ref({});

    onBeforeMount(()=>{
      projectsData.value = Data;
    });

    onMounted(() => {
      // console.log('mounted home page');
      Func.resetPage();
      Func.setupScrolltop();
      if(userMode.value == 'dark') {
        $(".site-projects").addClass('darkmode');
      } else {
        $(".site-projects").removeClass('darkmode');
      }

      // filter function
      $(".filter").on("click", (e)=> {
        e.preventDefault();
        let t = $(this);
        // state changes
        // logic: remove all active and pass filter = this. if active => non-active
        // no filter active == show all
        $('.filter').each(function (e) {
          $(this).removeClass('active');
        });

        if (t.data('filter') != 'rn') { // !== currentFilter
          t.toggleClass('active');
        }

        // filtering the display
        const $container = $('.test');
        const $data = $container.find($container.data('.list-searchjs') || $('.project-name').parent());
        // const eleTarget = this.options.searchJs || '.name';
        const eleTarget = '.project-name';
        
        const keyword = '2021';

        $data.filter((_i, el) => {
          console.log($(el).find(eleTarget).text());
          $(el).toggle(
            $(el).find(eleTarget).text().toLowerCase().indexOf(keyword) > -1
          );
          return el;
        });

        // this.redrawVueMasonry('.masonry-container');
      });
    });

    return {
      projectsData
    }
  }
}
</script>

<style scoped>
</style>
